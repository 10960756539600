var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mx-0 bg-white mx-4"},[(_vm.showCopySuccessModal)?_c('AppModal',{attrs:{"closeIcon":"","title":"Copied to clipboard"},on:{"onClose":_vm.handleCloseCopySuccessModal}}):_vm._e(),_c('div',{staticClass:"pl-5 pt-5 wrapper"},[_c('div',{staticClass:"col-12 px-4 mt-4 mt-lg-2 box1"},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"label-text text-uppercase bright-purple-color"},[_vm._v(" Playlist Name ")]),_c('h3',{staticClass:"title"},[_vm._v(_vm._s(_vm.playlist.name))])]),_c('span',{staticClass:"label-text text-uppercase bright-purple-color d-flex mb-lg-3 mb-2"},[_vm._v(" Tracklist Display ")]),_c('div',{staticClass:"row mx-0 mt-0 align-items-center mb-4"},[_c('h3',{staticClass:"title mb-0 color-gray"},[_vm._v(_vm._s(_vm.user.playlistsDisplayingPreference))]),_c('router-link',{staticClass:"label-text-v2 color-gray d-flex ml-3",attrs:{"to":{
            name: 'Display'
          }}},[_vm._v(" (Change Settings) ")])],1),_c('span',{staticClass:"label-text text-uppercase bright-purple-color d-flex mb-lg-3 mb-2"},[_vm._v(" Display Artist social handles ")]),_vm._m(0)]),_c('div',{staticClass:"col-12 px-4 mt-lg-2 mb-lg-2 box2"},[_c('div',{ref:"text",staticClass:"track-list-wrapper bg-gray mb-5 pl-lg-5 pt-lg-5 pb-lg-3 pr-lg-4 px-4 py-4"},[_c('simplebar',{staticClass:"track-list-scrolled d-flex flex-column pr-4"},[_c('span',{staticClass:"label-text mb-4 d-flex color-purple"},[_vm._v(" "+_vm._s(_vm.description + "\n\n")+" ")]),(_vm.user.primeArtistName)?_c('span',{staticClass:"label-text d-flex color-purple"},[_vm._v(" "+_vm._s(_vm.user.primeArtistName + "\n")+" ")]):_vm._e(),(_vm.playlist.datePlayed)?_c('span',{staticClass:"label-text d-flex color-purple"},[_vm._v(" "+_vm._s(_vm.momentDate(_vm.playlist.datePlayed) + "\n")+" ")]):_vm._e(),(_vm.playlist.venue)?_c('span',{staticClass:"label-text d-flex color-purple"},[_vm._v(" "+_vm._s(_vm.playlist.venue + "\n")+" ")]):_vm._e(),(_vm.playlist.city && _vm.playlist.country)?_c('span',{staticClass:"label-text d-flex color-purple mb-3"},[_vm._v(" "+_vm._s((_vm.playlist.city) + ", " + (_vm.playlist.country) + "\n\n")+" ")]):_vm._e(),_vm._l((_vm.playlist.tracks),function(track,index){return _c('div',{key:index,staticClass:"d-flex"},[_c('div',{staticClass:"d-flex label-text mr-2"},[_vm._v(_vm._s(index + 1)+".")]),(!_vm.playlist.hiddenTracks.find(function (el) { return el.id === track.id; }))?_c('div',{staticClass:"d-flex flex-column mb-3"},[(track.performers.length)?_c('span',{staticClass:"label-text d-flex"},[_vm._v(" Artist: "+_vm._s(track.performers.map(function (ref) {
                    var name = ref.name;

                    return name;
          }).join(" & "))+" ")]):_vm._e(),(track.remixers.length)?_c('span',{staticClass:"label-text d-flex"},[_vm._v(" Remixer: "+_vm._s(track.remixers.map(function (ref) {
                    var name = ref.name;

                    return name;
          }).join(" & "))+" ")]):_vm._e(),(
                  _vm.user.playlistsDisplayingPreference === 'Full track info' &&
                    track.name
                )?_c('span',{staticClass:"label-text d-flex"},[_vm._v(" Track: "+_vm._s(track.name + "\n\n")+" ")]):_vm._e()]):_c('div',{staticClass:"d-flex flex-column mb-3"},[(track.performers.length)?_c('span',{staticClass:"label-text d-flex"},[_vm._v(" Artist: Hidden by DJ ")]):_vm._e(),(track.remixers.length)?_c('span',{staticClass:"label-text d-flex"},[_vm._v(" Remixer: Hidden by DJ ")]):_vm._e(),(
                  _vm.user.playlistsDisplayingPreference === 'Full track info' &&
                    track.name
                )?_c('span',{staticClass:"label-text d-flex"},[_vm._v(" Track: Hidden by DJ ")]):_vm._e()])])})],2)],1)]),_c('div',{staticClass:"spacer flex-column flex-grow-1 mt-2 py-3 py-lg-0 px-4 d-flex align-items-lg-start align-items-center box3"},[_c('PrimaryButton',{staticClass:"text-decoration-none my-4 my-lg-5 px-4 justify-content-lg-start justify-content-center",attrs:{"type":"button"},on:{"click":function($event){return _vm.copyButtonHandler()}}},[_vm._v(" Copy text ")])],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-0 mt-0 mb-4 social-handles"},[_c('div',{staticClass:"social-handles-item"},[_c('input',{attrs:{"type":"checkbox","name":"None","id":"none"}}),_c('label',{staticClass:"custom-checkbox",attrs:{"for":"none"}}),_c('label',{attrs:{"for":"none"}},[_vm._v("None")])]),_c('div',{staticClass:"social-handles-item"},[_c('input',{attrs:{"type":"checkbox","name":"Twitter","id":"twitter"}}),_c('label',{staticClass:"custom-checkbox",attrs:{"for":"twitter"}}),_c('label',{attrs:{"for":"twitter"}},[_vm._v("Twitter")])]),_c('div',{staticClass:"social-handles-item"},[_c('input',{attrs:{"type":"checkbox","name":"Instagram","id":"instagram"}}),_c('label',{staticClass:"custom-checkbox",attrs:{"for":"instagram"}}),_c('label',{attrs:{"for":"instagram"}},[_vm._v("Instagram")])]),_c('div',{staticClass:"social-handles-item"},[_c('input',{attrs:{"type":"checkbox","name":"Facebook","id":"facebook"}}),_c('label',{staticClass:"custom-checkbox",attrs:{"for":"facebook"}}),_c('label',{attrs:{"for":"facebook"}},[_vm._v("Facebook")])])])}]

export { render, staticRenderFns }