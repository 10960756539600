<template>
  <div class="row mx-0 bg-white mx-4">
    <AppModal
      v-if="showCopySuccessModal"
      closeIcon
      title="Copied to clipboard"
      @onClose="handleCloseCopySuccessModal"
    />
    <div class="pl-5 pt-5 wrapper">
      <div class="col-12 px-4 mt-4 mt-lg-2 box1">
        <div class="d-flex flex-column">
          <span class="label-text text-uppercase bright-purple-color">
            Playlist Name
          </span>
          <h3 class="title">{{ playlist.name }}</h3>
        </div>
        <span class="label-text text-uppercase bright-purple-color d-flex mb-lg-3 mb-2">
          Tracklist Display
        </span>
        <div class="row mx-0 mt-0 align-items-center mb-4">
          <h3 class="title mb-0 color-gray">{{ user.playlistsDisplayingPreference }}</h3>
          <router-link
            :to="{
              name: 'Display'
            }"
            class="label-text-v2 color-gray d-flex ml-3"
          >
            (Change Settings)
          </router-link>
        </div>

        <span class="label-text text-uppercase bright-purple-color d-flex mb-lg-3 mb-2">
          Display Artist social handles
        </span>
        <div class="mx-0 mt-0 mb-4 social-handles">
          <div class="social-handles-item">
            <input type="checkbox" name="None" id="none" />
            <label for="none" class="custom-checkbox"></label>
            <label for="none">None</label>
          </div>
          <div class="social-handles-item">
            <input type="checkbox" name="Twitter" id="twitter" />
            <label for="twitter" class="custom-checkbox"></label>
            <label for="twitter">Twitter</label>
          </div>
          <div class="social-handles-item">
            <input type="checkbox" name="Instagram" id="instagram" />
            <label for="instagram" class="custom-checkbox"></label>
            <label for="instagram">Instagram</label>
          </div>
          <div class="social-handles-item">
            <input type="checkbox" name="Facebook" id="facebook" />
            <label for="facebook" class="custom-checkbox"></label>
            <label for="facebook">Facebook</label>
          </div>
        </div>
      </div>
      <div class="col-12 px-4 mt-lg-2 mb-lg-2 box2">
        <div
          class="track-list-wrapper bg-gray mb-5 pl-lg-5 pt-lg-5 pb-lg-3 pr-lg-4 px-4 py-4"
          ref="text"
        >
          <simplebar class="track-list-scrolled d-flex flex-column pr-4">
            <span class="label-text mb-4 d-flex color-purple">
              {{ description + "\n\n" }}
            </span>
            <span
              v-if="user.primeArtistName"
              class="label-text d-flex color-purple"
            >
              {{ user.primeArtistName + "\n" }}
            </span>
            <span
              v-if="playlist.datePlayed"
              class="label-text d-flex color-purple"
            >
              {{ momentDate(playlist.datePlayed) + "\n" }}
            </span>
            <span v-if="playlist.venue" class="label-text d-flex color-purple">
              {{ playlist.venue + "\n" }}
            </span>
            <span
              v-if="playlist.city && playlist.country"
              class="label-text d-flex color-purple mb-3"
            >
              {{ `${playlist.city}, ${playlist.country}` + "\n\n" }}
            </span>
            <div
              v-for="(track, index) in playlist.tracks"
              :key="index"
              class="d-flex"
            >
              <div class="d-flex label-text mr-2">{{ index + 1 }}.</div>
              <div
                class="d-flex flex-column mb-3"
                v-if="!playlist.hiddenTracks.find(el => el.id === track.id)"
              >
                <span v-if="track.performers.length" class="label-text d-flex">
                  Artist:
                  {{ track.performers.map(({ name }) => name).join(" & ") }}
                </span>
                <span v-if="track.remixers.length" class="label-text d-flex">
                  Remixer:
                  {{ track.remixers.map(({ name }) => name).join(" & ") }}
                </span>
                <span
                  v-if="
                    user.playlistsDisplayingPreference === 'Full track info' &&
                      track.name
                  "
                  class="label-text d-flex"
                >
                  Track: {{ track.name + "\n\n" }}
                </span>
              </div>
              <div class="d-flex flex-column mb-3" v-else>
                <span v-if="track.performers.length" class="label-text d-flex">
                  Artist: Hidden by DJ
                </span>
                <span v-if="track.remixers.length" class="label-text d-flex">
                  Remixer: Hidden by DJ
                </span>
                <span
                  v-if="
                    user.playlistsDisplayingPreference === 'Full track info' &&
                      track.name
                  "
                  class="label-text d-flex"
                >
                  Track: Hidden by DJ
                </span>
              </div>
            </div>
          </simplebar>
        </div>
      </div>
      <div
        class="spacer flex-column flex-grow-1 mt-2 py-3 py-lg-0 px-4 d-flex align-items-lg-start align-items-center box3"
      >
        <PrimaryButton
          class="text-decoration-none my-4 my-lg-5 px-4 justify-content-lg-start justify-content-center"
          type="button"
          @click="copyButtonHandler()"
        >
          Copy text
        </PrimaryButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import swal from "sweetalert2";
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
import AppModal from "@/components/AppModal.vue";
import PrimaryButton from "@/components/global/buttons/PrimaryButton.vue";

export default {
  name: "PlaylistSharingTrackList",
  components: {
    PrimaryButton,
    AppModal,
    simplebar
  },
  data() {
    return {
      showCopySuccessModal: false,
      description:
        "Learn how www.aslice.com is building a more equitable music economy."
    };
  },
  computed: {
    ...mapState("playlistSharing", ["playlist"]),
    ...mapState("user", {
      user: "data"
    }),
    isFullTrackInfo() {
      return this.user.playlistsDisplayingPreference === "Full track info";
    }
  },
  methods: {
    handleCloseCopySuccessModal() {
      this.showCopySuccessModal = false;
    },
    momentDate(data) {
      return moment(data, moment.ISO_8601).format("DD/MM/YYYY");
    },

    copyButtonHandler() {
      let reducer = "";
      for (const child of this.$refs.text.children) {
        reducer += child.textContent + "\n";
      }
      try {
        navigator.clipboard.writeText(reducer);
        this.showCopySuccessModal = true;
      } catch (error) {
        swal.fire({
          icon: "error",
          text: error.message
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.box1 {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}
.box2 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 5;
}
.box3 {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
}

@media only screen and (max-width: 1200px) {
  .wrapper {
    display: grid;
    grid-template-columns: 1fr;
  }
  .box1 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .box2 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .box3 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
  }
}
@media only screen and (max-width: 992px) {}
@media only screen and (max-width: 768px) {}
@media only screen and (max-width: 576px) {}

.primary-button  {
  margin-left: 0;
  margin-top: 0 !important;
}

.bright-purple-color {
  color: rgba(152, 86, 252, 1) !important;
}

.social-handles {
  display: flex;
  flex-wrap: wrap;
  max-width: 256px;
  align-items: center;
  row-gap: 16px;

  div {
    display: flex;
    min-width: 128px;
    align-items: center;
    gap: 10px;

    label {
      margin-bottom: 0;
      color: rgba(145, 145, 143, 1);
    }

    /* Hide the default checkbox */
    input[type="checkbox"] {
      display: none;
    }

    /* Create a custom checkbox */
    .custom-checkbox {
      display: inline-block;
      width: 20px;
      height: 20px;
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid rgba(145, 145, 143, 1);
      position: relative;
      cursor: pointer;
    }

    /* Checkmark for the custom checkbox */
    .custom-checkbox:after {
      content: '';
      position: absolute;
      top: 40%;
      left: 50%;
      width: 10px;
      height: 6px;
      border: 1px solid white;
      border-top: none;
      border-right: none;
      transform: translate(-50%, -50%) rotate(-45deg);
      opacity: 0;
      transition: opacity 0.2s;
    }

    /* Change the background color and show the checkmark when checked */
    input[type="checkbox"]:checked + .custom-checkbox {
      background-color: rgba(68, 38, 113, 1);
    }

    input[type="checkbox"]:checked + .custom-checkbox:after {
      opacity: 1;
    }

    /* Optional: change the label color */
    input[type="checkbox"]:checked + .custom-checkbox + label {
      color: rgba(68, 38, 113, 1);
    }
  }
}

.bg-white {
  background-color: white;
}

.bg-gray {
  background: #f2f1ef;
}

.mt-lg-6 {
  @media only screen and (min-width: 992px) {
    margin-top: 60px !important;
  }
}

.label-text {
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0.15em;
}

.label-text-v2 {
  padding-top: 6px;
  font-size: 14px;
  line-height: 11px;
  letter-spacing: 0.15em;
  color: #AAA3B1;
}

.title {
  font-size: 24px;
  line-height: 42px;
  color: #442671;
}

.track-list-wrapper {
  max-width: 678px;
  max-height: 377px;
  background-color: #FAF9F9;

  .label-text {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: normal;
  }
}

.track-list-scrolled {
  max-height: 339px;
}

@media only screen and (min-width: 992px) {
  .track-list-wrapper {
    max-height: 536px;
  }
  .track-list-scrolled {
    max-height: 483px;
  }
}

::v-deep .simplebar-scrollbar:before {
  color: #c4c4c4;
  width: 5px;
  opacity: 0.3;
}

.rounded-button {
  border: none;
  color: #fff;
  background: #442671;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #442671 !important;
    background: #fff;
  }
}
</style>
